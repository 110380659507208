<template>
  <div class="contents register toy">
    <div class="title flexB">
      <h2>{{ editMode ? "공지사항 수정" : "공지사항 등록" }}</h2>
    </div>
    <div class="box one">
      <div class="mh">
        <div class="flexL">
          <p class="bold">
            카테고리
            <span>*</span>
          </p>
          <select class="searchCondition" v-model="category">
            <option value disabled>선택</option>
            <option value="NOTICE">공지</option>
            <option value="EVENT">이벤트</option>
          </select>
        </div>
        <div class="flexL">
          <p class="bold">
            제목
            <span>*</span>
          </p>
          <input type="text" v-model="title" />
        </div>

        <div class="flexL">
          <p class="bold">
            상세 내용
            <span>*</span>
          </p>
          <textarea v-model="content" />
        </div>
        <label>
          <input type="checkbox" id="fcmCheck" v-model="fcmCheck" />
          모든 사용자에게 푸쉬 메세지를 자동 발송합니다.
        </label>
      </div>
      <div class="buttonWrap">
        <button class="basic" @click="submit">
          {{ editMode == true ? "수정" : "등록" }}
        </button>
        <button v-if="editMode" class="line" @click="handleDelete">삭제</button>
      </div>
    </div>
  </div>
</template>
<style scoped>
#fcmCheck {
  -webkit-appearance: auto;
}
</style>
<script>
import moment from "moment";
import { createNotice, upadateNoticeDetails, fetchNoticeDetails, removeNotice, createNoticeFcm } from "@/api/admin";
export default {
  data() {
    return {
      editMode: false,
      moment: moment,
      category: "",
      title: "",
      content: "",
      noticeId: "",
      fcmCheck: false,
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", "5");
  },
  mounted() {
    if (this.$route.query.id) {
      this.editMode = true;
      this.noticeId = this.$route.query.id;
      this.getNoticeDetail();
    }
  },
  methods: {
    openAlert(msg) {
      this.$alert(msg, {
        confirmButtonText: "확인",
        center: true,
      });
    },
    getNoticeDetail() {
      fetchNoticeDetails(this.noticeId).then((res) => {
        if (res.data.status == 200) {
          this.category = res.data.data.category;
          this.title = res.data.data.title;
          this.content = res.data.data.content;
        } else {
          this.openAlert(res.data.message);
        }
      });
    },
    handleDelete() {
      this.$confirm("삭제하시겠습니까?", {
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        center: true,
      }).then(() => {
        removeNotice(this.noticeId).then((res) => {
          if (res.data.status == 200) {
            this.$notify({
              title: "Success",
              message: "공지사항 정보가 삭제 되었습니다.",
              type: "success",
            });
            this.$router.push("/admin/notices");
          }
        });
      });
    },
    submit() {
      if (this.category == "") {
        this.openAlert("카테고리를 선택해 주세요.");
        return;
      } else if (this.title == "") {
        this.openAlert("제목을 입력해 주세요.");
        return;
      } else if (this.content == "") {
        this.openAlert("상세 내용을 입력해 주세요.");
        return;
      }
      let data = {
        category: this.category,
        title: this.title,
        content: this.content,
        noticeId: this.noticeId,
      };
      if (this.editMode) {
        upadateNoticeDetails(data).then((res) => {
          if (res.data.status == 200) {
            this.openAlert("공지사항이 수정 되었습니다.");
            this.$router.push("/admin/notices");
          } else {
            this.openAlert(res.data.message);
          }
        });
      } else {
        createNotice(data).then((res) => {
          if (res.data.status == 200) {
            this.openAlert("공지사항 등록이 완료 되었습니다.");
            this.$router.push("/admin/notices");
          } else {
            this.openAlert(res.data.message);
          }
        });
      }
      if (this.fcmCheck) {
        data.url = "notice";
        createNoticeFcm(data).then((res) => {
          if (res.data.status == 200) {
            console.log("공지사항 등록이 완료 되었습니다. fcm");
          } else {
            let message = res.data.message;
            alert(message);
          }
        });
      }
    },
  },
};
</script>
